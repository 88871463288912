// src/utils/metaPixel.ts

import axios from 'axios';
import crypto from 'crypto';
import { v4 as uuidv4 } from 'uuid';
import { safeStringify } from '@/utils/api';

const isBrowser = typeof window !== 'undefined';
const PIXEL_ID = '507409058731664';

export const initializeMetaPixel = () => {
  if (isBrowser) {
    import('react-facebook-pixel')
      .then((module) => {
        const ReactPixel = module.default;
        ReactPixel.init(PIXEL_ID);
        ReactPixel.pageView();
      })
      .catch((err) => console.error('Error initializing Meta Pixel:', safeStringify(err)));
  } else {
    console.log('Meta Pixel initialization skipped (not in browser)');
  }
};

export const hashData = (data: string): string => {
  return crypto.createHash('sha256').update(data).digest('hex');
};

const sendServerSideEvent = async (eventName: string, eventData: any) => {
  try {
    const event_id = uuidv4();
    console.log('Sending server-side event:', { eventName, eventData, event_id });

    const response = await axios.post('/api/meta-conversion', {
      eventName,
      eventData,
      event_id,
    });

    console.log('Server-side event response:', response.data);
  } catch (error) {
    console.error('Error sending server-side event:', safeStringify(error));
    if (axios.isAxiosError(error) && error.response) {
      console.error('Meta API Error Response:', safeStringify(error.response.data));
    }
  }
};

export const getCookie = (cookieName: string): string | null => {
  if (!isBrowser) return null;
  const name = cookieName + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

export const trackViewContent = (contentName: string, url: string, userEmail: string) => {
  const fbp = getCookie('_fbp');
  const fbc = getCookie('_fbc');

  const eventData = {
    event_name: 'ViewContent',
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    event_source_url: url,
    user_data: {
      em: [hashData(userEmail.toLowerCase())],
      fbp,
      fbc,
    },
    custom_data: {
      content_name: contentName,
    },
  };
  sendServerSideEvent('ViewContent', eventData);
};

export const trackPurchase = (value: number, currency: string, transactionId: string, userEmail: string) => {
  const fbp = getCookie('_fbp');
  const fbc = getCookie('_fbc');

  const eventData = {
    event_name: 'Purchase',
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    event_source_url: isBrowser ? window.location.href : '',
    user_data: {
      em: [hashData(userEmail.toLowerCase())],
      fbp,
      fbc,
    },
    custom_data: {
      currency,
      value,
      transaction_id: transactionId,
    },
  };
  sendServerSideEvent('Purchase', eventData);
};

export const trackCompleteRegistration = (userEmail: string) => {
  const fbp = getCookie('_fbp');
  const fbc = getCookie('_fbc');

  const eventData = {
    event_name: 'CompleteRegistration',
    event_time: Math.floor(Date.now() / 1000),
    action_source: 'website',
    event_source_url: isBrowser ? window.location.href : '',
    user_data: {
      em: [hashData(userEmail.toLowerCase())],
      fbp,
      fbc,
    },
  };
  sendServerSideEvent('CompleteRegistration', eventData);
};