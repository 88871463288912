// src/utils/api.ts

import axios from 'axios';

import { DocumentInfo, CostBreakdown, JobStatus, FormattedJobStatus, CreditCostResult, PromoCodeResult } from '@/types';
import { notification } from 'antd'; // Import Ant Design's notification

// Environment variable parsing with exact names
const MINIMUM_BASE_COST_IN_CREDITS = parseInt(process.env.NEXT_PUBLIC_MINIMUM_BASE_COST_IN_CREDITS || '2', 10);
const NUMBER_OF_CREDITS_PER_1000_WORDS = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_PER_1000_WORDS || '3', 10);
const NUMBER_OF_CREDITS_PER_QUIZ = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_PER_QUIZ || '5', 10);
const NUMBER_OF_CREDITS_DISCOUNT_FOR_BOTH_QUIZZES = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_DISCOUNT_FOR_BOTH_QUIZZES || '3', 10);
const NUMBER_OF_CREDITS_FOR_ANKI_FLASHCARDS = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_FOR_ANKI_FLASHCARDS || '4', 10);
const NUMBER_OF_CREDITS_FOR_PRESENTATION_SLIDES = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_FOR_PRESENTATION_SLIDES || '4', 10);
const NUMBER_OF_CREDITS_FOR_SUMMARY = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_FOR_SUMMARY || '5', 10);
const NUMBER_OF_CREDITS_FOR_MINDMAP = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_FOR_MINDMAP || '12', 10);
const NUMBER_OF_CREDITS_FOR_READABILITY_ANALYSIS = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_FOR_READABILITY_ANALYSIS || '10', 10);
const NUMBER_OF_CREDITS_FOR_LESSON_PLAN = parseInt(process.env.NEXT_PUBLIC_NUMBER_OF_CREDITS_FOR_LESSON_PLAN || '6', 10);
const GLOBAL_PRICING_MULTIPLIER = parseFloat(process.env.NEXT_PUBLIC_GLOBAL_PRICING_MULTIPLIER || '0.8');

// Helper Functions

/**
 * Calculates the credit cost based on the provided file.
 * @param file - The file for which to calculate the credit cost.
 * @returns A promise that resolves to the credit cost result.
 */
export const calculateCreditCost = async (file: File | null): Promise<CreditCostResult> => {
  console.log('calculateCreditCost called with file:', file?.name || 'No file');

  if (!file) {
    console.log('calculateCreditCost: No file provided, returning default cost');
    return {
      documentInfo: {
        document_id: '',
        fileName: '',
        fileSize: 0,
        originalFileHash: null,
        totalPages: 0,
        wordCount: 0,
        mimeType: '',
        sanitizedFileName: '',
        uniqueIdentifier: '',
        ocrRequired: false,
        title: '',
        costBreakdown: {
          baseCost: 0,
          multipleChoiceCost: 0,
          shortAnswerCost: 0,
          ankiFlashcardsCost: 0,
          presentationSlidesCost: 0,
          summaryCost: 0,
          mindmapCost: 0,
          readabilityAnalysisCost: 0,
          lessonPlanCost: 0,
          quizDiscount: 0,
          totalCost: 0,
        },
      },
      costBreakdown: {
        baseCost: 0,
        multipleChoiceCost: 0,
        shortAnswerCost: 0,
        ankiFlashcardsCost: 0,
        presentationSlidesCost: 0,
        summaryCost: 0,
        mindmapCost: 0,
        readabilityAnalysisCost: 0,
        lessonPlanCost: 0,
        quizDiscount: 0,
        totalCost: 0,
      },
    };
  }

  try {
    console.log('Calling getDocumentInfo');
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post<DocumentInfo>('/api/get_document_info', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    console.log('getDocumentInfo result:', response.data);

    const documentInfo = response.data;

    if (!documentInfo.wordCount || isNaN(documentInfo.wordCount)) {
      console.error('Invalid word count:', documentInfo.wordCount);
      throw new Error('Invalid document word count');
    }

    // Calculate base cost based on word count
    const baseCost =
      documentInfo.wordCount === 0
        ? 0
        : Math.max(MINIMUM_BASE_COST_IN_CREDITS, Math.ceil(documentInfo.wordCount / 1000) * NUMBER_OF_CREDITS_PER_1000_WORDS);

    // Calculate feature costs with global pricing multiplier
    const multipleChoiceCost = Math.ceil(NUMBER_OF_CREDITS_PER_QUIZ * GLOBAL_PRICING_MULTIPLIER);
    const shortAnswerCost = Math.ceil(NUMBER_OF_CREDITS_PER_QUIZ * GLOBAL_PRICING_MULTIPLIER);
    const ankiFlashcardsCost = Math.ceil(NUMBER_OF_CREDITS_FOR_ANKI_FLASHCARDS * GLOBAL_PRICING_MULTIPLIER);
    const presentationSlidesCost = Math.ceil(NUMBER_OF_CREDITS_FOR_PRESENTATION_SLIDES * GLOBAL_PRICING_MULTIPLIER);
    const summaryCost = Math.ceil(NUMBER_OF_CREDITS_FOR_SUMMARY * GLOBAL_PRICING_MULTIPLIER);
    const mindmapCost = Math.ceil(NUMBER_OF_CREDITS_FOR_MINDMAP * GLOBAL_PRICING_MULTIPLIER);
    const readabilityAnalysisCost = Math.ceil(NUMBER_OF_CREDITS_FOR_READABILITY_ANALYSIS * GLOBAL_PRICING_MULTIPLIER);
    const lessonPlanCost = Math.ceil(NUMBER_OF_CREDITS_FOR_LESSON_PLAN * GLOBAL_PRICING_MULTIPLIER);
    const quizDiscount = Math.ceil(NUMBER_OF_CREDITS_DISCOUNT_FOR_BOTH_QUIZZES * GLOBAL_PRICING_MULTIPLIER);

    // Calculate total cost
    const totalCost =
      baseCost +
      multipleChoiceCost +
      shortAnswerCost +
      ankiFlashcardsCost +
      presentationSlidesCost +
      summaryCost +
      mindmapCost +
      readabilityAnalysisCost +
      lessonPlanCost -
      quizDiscount;

    const costBreakdown: CostBreakdown = {
      baseCost,
      multipleChoiceCost,
      shortAnswerCost,
      ankiFlashcardsCost,
      presentationSlidesCost,
      summaryCost,
      mindmapCost,
      readabilityAnalysisCost,
      lessonPlanCost,
      quizDiscount,
      totalCost,
    };

    return {
      documentInfo: {
        ...documentInfo,
        costBreakdown,
      },
      costBreakdown,
    };
  } catch (error: unknown) {
    console.error('calculateCreditCost: Error calculating credit cost:', safeStringify(error));
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.error || 'Failed to calculate credit cost');
    }
    throw new Error('Failed to calculate credit cost');
  }
};

/**
 * Applies a promo code.
 * @param code - The promo code to apply.
 * @returns A promise that resolves to the promo code result.
 */
export const applyPromoCode = async (code: string): Promise<PromoCodeResult> => {
  try {
    const response = await axios.post<PromoCodeResult>('/api/promo/apply', { promoCode: code });
    return response.data;
  } catch (error: unknown) {
    console.error('Error applying promo code:', safeStringify(error));
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.error || 'Failed to apply promo code');
    }
    throw new Error('Failed to apply promo code');
  }
};

/**
 * Handles the download of processed documents.
 * Uses the filename provided by the FastAPI backend directly.
 * @param job_id - The job ID associated with the download.
 */
export const handleDownload = async (job_id: string) => {
  if (!job_id) {
    notification.error({
      message: 'Download Error',
      description: 'Job ID is required.',
      placement: 'topRight',
    });
    throw new Error('Job ID is required');
  }

  try {
    const response = await axios.get(`/api/download/${job_id}`, {
      responseType: 'blob',
      timeout: 600000, // 10 minutes
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });

    // Check if we got valid data
    if (!response.data || (response.data as Blob).size === 0) {
      throw new Error('No data received in download response');
    }

    // Create object URL and trigger download
    const blob = new Blob([response.data], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;

    // Use Content-Disposition header or fall back to a default name
    const contentDisposition = response.headers['content-disposition'];
    let filename = `result_${job_id}.zip`;
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"/);
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1];
      }
    }

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    // Optionally, show a success notification
    notification.success({
      message: 'Download Successful',
      description: `Your file "${filename}" has been downloaded.`,
      placement: 'topRight',
    });
  } catch (error: unknown) {
    console.error('Error downloading file:', safeStringify(error));
    let errorMessage = 'An unexpected error occurred.';

    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        errorMessage = 'File not found or job does not exist.';
      } else if (error.response?.status === 400) {
        errorMessage = 'Job is not ready for download yet.';
      } else if (error.response?.status === 401) {
        errorMessage = 'Unauthorized to download this file.';
      } else if (error.response?.status === 429) {
        errorMessage = 'A download is already in progress for this job.';
      } else if (error.message.includes('timeout')) {
        errorMessage = 'Download request timed out.';
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      }
    }

    notification.error({
      message: 'Download Error',
      description: errorMessage,
      placement: 'topRight',
    });

    throw error;
  }
};

/**
 * Checks the status of a job by its ID.
 * @param job_id - The job ID to check.
 * @returns A promise that resolves to the formatted job status.
 */
export const checkJobStatus = async (job_id: string): Promise<FormattedJobStatus> => {
  try {
    console.log(`Checking job status for job_id: ${job_id}`);
    const response = await axios.get<JobStatus>(`/api/job_status/${job_id}`);
    console.log('Job status response:', JSON.stringify(response.data, null, 2));

    const jobStatus = response.data;
    const formattedJobStatus: FormattedJobStatus = {
      ...jobStatus,
      formattedLastProcessed: jobStatus.last_processed ? new Date(jobStatus.last_processed).toLocaleString() : null,
      formattedLastOptimized: jobStatus.last_optimized ? new Date(jobStatus.last_optimized).toLocaleString() : null,
    };

    return formattedJobStatus;
  } catch (error: unknown) {
    console.error('Error checking job status:', safeStringify(error));
    if (axios.isAxiosError(error)) {
      console.error(
        'Axios error details:',
        safeStringify({
          response: error.response?.data,
          status: error.response?.status,
          headers: error.response?.headers,
        })
      );
    }
    throw new Error('Failed to check job status');
  }
};

/**
 * Retrieves metadata for a given document file.
 * @param file - The file for which to retrieve metadata.
 * @returns A promise that resolves to the document's total pages and MIME type.
 */
export async function getDocumentMetadata(file: File): Promise<{ totalPages: number; mimeType: string }> {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post<{ totalPages: number; mimeType: string }>('/api/get_document_metadata', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  } catch (error: unknown) {
    console.error('Error getting document metadata:', safeStringify(error));
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.error || 'Failed to get document metadata');
    }
    throw new Error('Failed to get document metadata');
  }
}

export const createDocumentFileName = (originalFileName: string): string => {
  // First remove the file extension
  const nameWithoutExtension = originalFileName.replace(/\.[^/.]+$/, '');

  // Remove any invalid characters and replace with underscores
  const safeName = nameWithoutExtension
    .replace(/[^a-z0-9]/gi, '_')
    .replace(/_+/g, '_')
    .replace(/^_+|_+$/g, '');

  // Get the file extension
  const extension = originalFileName.split('.').pop()?.toLowerCase() || '';

  const baseName = `Combined_Document_Output_File_Bundle__Document__`;
  const maxSafeNameLength = 255 - baseName.length - (extension ? extension.length + 1 : 0);

  // Truncate safeName if it's too long
  const truncatedSafeName = safeName.length > maxSafeNameLength ? safeName.substring(0, maxSafeNameLength) : safeName;

  // Combine parts, using the extension only once
  return `${baseName}${truncatedSafeName}${extension ? `_${extension}` : ''}`;
};

export const getDisplayFileType = (mimeType: string): string => {
  const mimeToExtension: Record<string, string> = {
    // Document Types
    'application/pdf': 'PDF',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
    'application/msword': 'DOC',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.ms-powerpoint': 'PPT',
    'text/plain': 'TXT',
    'application/rtf': 'RTF',

    // Image Types
    'image/jpeg': 'JPG',
    'image/png': 'PNG',
    'image/gif': 'GIF',
    'image/bmp': 'BMP',
    'image/tiff': 'TIFF',
    'image/webp': 'WEBP',

    // Audio Types
    'audio/mpeg': 'MP3',
    'audio/wav': 'WAV',
    'audio/mp4': 'M4A',
    'audio/ogg': 'OGG',
    'audio/aac': 'AAC',
    'audio/x-ms-wma': 'WMA',
    'audio/flac': 'FLAC',

    // Video Types
    'video/mp4': 'MP4',
    'video/x-matroska': 'MKV',
    'video/x-msvideo': 'AVI',
    'video/quicktime': 'MOV',
    'video/x-ms-wmv': 'WMV',
    'video/webm': 'WEBM',

    // Email Types
    'message/rfc822': 'MSG',
    'application/vnd.ms-outlook': 'MSG',
    'application/eml': 'EML',

    // Archive Types (if needed)
    'application/zip': 'ZIP',
    'application/x-zip-compressed': 'ZIP',
    'application/x-zip': 'ZIP',

    // Web and Data Types
    'text/csv': 'CSV',
    'application/json': 'JSON',
    'text/markdown': 'MD',
    'text/html': 'HTML',
  };

  return mimeToExtension[mimeType] || 'UNKNOWN';
};

// Helper function to safely stringify objects with circular references
// Helper function to safely stringify objects with circular references
export function safeStringify(obj: any, seen = new WeakSet()): string {
  try {
    return JSON.stringify(
      obj,
      (key, value) => {
        // Handle circular references
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return '[Circular Reference]';
          }
          seen.add(value);
        }

        // Handle Errors
        if (value instanceof Error) {
          const { stack, ...rest } = value;
          return {
            ...rest,
            stack: stack || undefined,
          };
        }

        // Handle functions
        if (typeof value === 'function') {
          return `[Function: ${value.name || 'anonymous'}]`;
        }

        // Handle symbols
        if (typeof value === 'symbol') {
          return `[Symbol: ${value.toString()}]`;
        }

        // Handle other problematic cases that might cause `f is not a function` inside JSON.stringify
        // For instance, objects with a toJSON method that isn't a real function
        if (value && typeof value === 'object' && 'toJSON' in value && typeof value.toJSON !== 'function') {
          // If toJSON is not a function, replace it with a string so JSON.stringify won't try to call it
          const { toJSON, ...rest } = value;
          return { ...rest, toJSON: '[Invalid toJSON]' };
        }

        return value;
      },
      2
    );
  } catch (error) {
    return `[Unable to stringify: ${error instanceof Error ? error.message : String(error)}]`;
  }
}
