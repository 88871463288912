// src/utils/linkedInTag.ts

const isBrowser = typeof window !== 'undefined';
const LINKEDIN_PARTNER_ID = '513206595';

export const initializeLinkedInTag = () => {
  if (isBrowser) {
    (window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || [];
    (window as any)._linkedin_data_partner_ids.push(LINKEDIN_PARTNER_ID);

    const s = document.getElementsByTagName("script")[0];
    const b = document.createElement("script");
    b.type = "text/javascript";
    b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode?.insertBefore(b, s);
  } else {
    console.log('LinkedIn Tag initialization skipped (not in browser)');
  }
};

export const trackConversion = (conversionId: string, value?: number) => {
  if (isBrowser && (window as any).lintrk) {
    (window as any).lintrk('track', { conversion_id: conversionId });
    if (value !== undefined) {
      (window as any).lintrk('track', { conversion_id: conversionId, value: value });
    }
  } else {
    console.log('LinkedIn conversion tracking skipped (not in browser or lintrk not found):', conversionId, value);
  }
};